import { createTheme } from "@mui/material/styles";

const BASE_THEME = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#005EAB",
    },
    secondary: {
      main: "#018DFF",
    },
    error: {
      main: "#DC3545",
    },
    warning: {
      main: "#ffc107",
    },
    success: {
      main: "#198754",
    },
    info: {
      main: "#018dff",
    },
    custom: {
      light: "#ffa726",
      main: "#f57c00",
      dark: "#ef6c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    divider: "#dfdfdf",
    background: {
      default: "#eff8ff",
      paper: "#ffffff",
    },
    text: {
      light: "#707070",
      main: "#808080",
      default: "#000000de",
      secondary: "#3c3c3c",
      dark: "#000000",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    fontSize: 14,
    fontWeight: 400,
    h1: {
      fontWeight: 700,
      fontSize: 24,
    },
    h2: {
      fontWeight: 700,
      fontSize: 20,
    },
    h3: {
      fontWeight: 700,
      fontSize: 18,
    },
    h4: {
      fontWeight: 700,
      fontSize: 16,
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
      fontStyle: "normal",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      fontStyle: "normal",
    },
    body1: {
      fontSize: 14,
      fontStyle: "normal",
    },
    body2: {
      fontSize: 13,
      fontStyle: "normal",
    },
    button: {
      fontSize: 14,
      textTransform: "none",
    },
    caption: {
      fontSize: 12,
    },
  },
  spacing: 4,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({ borderRadius: "6px" }),
      },
    },
  },
});

export default BASE_THEME;
