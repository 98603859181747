const jsonToCSV = (jsonData) => {
  const headers = Object.keys(jsonData[0]); // Get the keys as headers
  const csvRows = [];

  // Add the headers as the first row
  csvRows.push(headers.join(","));

  // Add each record as a CSV row
  jsonData.forEach((record) => {
    const row = headers.map((header) => escapeCSV(record[header]));
    csvRows.push(row.join(","));
  });

  return csvRows.join("\n");
};

// This function checks if the value contains commas, quotes, or newlines and wraps the value in double quotes if necessary.
// If there are internal quotes, it escapes them by doubling the quotes ("")
const escapeCSV = (value) => {
  if (value == null) return "";
  value = value.toString();

  // If value contains commas, quotes, or newlines, wrap in quotes and escape inner quotes
  if (value.includes('"') || value.includes(",") || value.includes("\n")) {
    value = `"${value.replace(/"/g, '""')}"`; // Escape quotes by doubling them
  }

  return value;
};

const downloadCSV = (jsonData, filename = "data.csv") => {
  const csvData = jsonToCSV(jsonData);
  const blob = new Blob([csvData], { type: "text/csv" });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

const FormUtils = {
  downloadCSV,
};

export default FormUtils;
