import { useState, useEffect, useCallback, useRef } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import styles from "styles/patients.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import DateUtils from "utils/DateUtils.js";
import PatientService from "services/PatientService.js";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import DATA_TABLE_THEME from "themes/DataTableTheme.js";
import PopUpModal from "components/utils/PopUpModal.js";
import ConfirmActionModal from "components/modals/ConfirmActionModal";
import useAPI from "hooks/useAPI.js";
import FormUtils from "utils/FormUtils";
import FileUtils from "utils/FileUtils";
import { useOutletContext } from "react-router-dom";

export function Patients() {
  const [hospitalId] = useOutletContext();

  return <Outlet context={[hospitalId]} />;
}

export function PatientList() {
  let navigate = useNavigate();
  const [hospitalId] = useOutletContext();

  const [patientList, setPatientList] = useState([]);
  const popUpModalRef = useRef(null);
  const [archivingPatientId, setArchivingPatientId] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    total: 0,
    search: "",
  });

  const optimisedSearchHandler = FormUtils.debounce((e) => {
    fetchPatients();
  }, 500);

  const fetchPatientsAPI = useAPI(
    PatientService.getAllPatients,
    fetchPatientsHandler,
    false
  );
  const archivePatientAPI = useAPI(
    PatientService.archivePatient,
    archivePatientHandler,
    true
  );
  const sendRegistrationEmailAPI = useAPI(
    PatientService.sendRegistrationEmail,
    () => {},
    true
  );

  const dataTableOptions = {
    filter: false,
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    download: true,
    downloadOptions: {
      filename: "Patients.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: () => {
      downloadAllPatientsData();
      return false;
    },
    jumpToPage: true,
    count: pagination.total,
    page: pagination.page,
    rowsPerPage: pagination.size,
    searchText: pagination.search,
    searchProps: {
      onKeyUp: optimisedSearchHandler,
    },
    onChangePage: (currentPage) => {
      pagination.page = currentPage;
      setPagination(pagination);
      fetchPatients();
    },
    onChangeRowsPerPage: (rowsPerPage) => {
      pagination.size = rowsPerPage;
      setPagination(pagination);
      fetchPatients();
    },
    onSearchChange: (searchText) => {
      if (searchText) {
        pagination.search = searchText;
      } else {
        pagination.search = "";
      }
      setPagination(pagination);
    },
    onSearchClose: () => {
      if (pagination.search !== "") {
        pagination.search = "";
        setPagination(pagination);
        fetchPatients();
      }
    },
  };

  useEffect(() => {
    fetchPatients();
  }, [hospitalId]);

  const fetchPatients = useCallback(() => {
    fetchPatientsAPI.execute(
      hospitalId,
      pagination.page,
      pagination.size,
      pagination.search
    );
  }, [fetchPatientsAPI]);

  async function downloadAllPatientsData() {
    const response = await PatientService.getAllPatients([hospitalId]);

    let patientsList = response.data.content;

    // remove redundant fields
    patientsList = patientsList.map((patient) => {
      delete patient.associatedHospitalId;
      delete patient.isArchived;
      delete patient.lastUpdatedAt;
      delete patient.profilePhotoUrl;

      return patient;
    });

    FileUtils.downloadCSV(patientsList, "Patients.csv");
  }

  function archivePatient(patientId) {
    archivePatientAPI.execute(hospitalId, patientId);
  }

  function sendRegistrationEmail(patientId) {
    sendRegistrationEmailAPI.execute(hospitalId, patientId);
  }

  /* API Success Handlers */
  function fetchPatientsHandler(response) {
    let data = response.data;

    pagination.total = data.totalElements;
    setPagination(pagination);

    let patients = [];
    data.content.map((patient, index) =>
      patients.push(
        transformJsonData(
          patient.id,
          patient.firstName + " " + patient.lastName,
          patient.phone,
          patient.email,
          patient.dob,
          patient.createdAt,
          index + 1
        )
      )
    );
    setPatientList(patients);
  }

  function archivePatientHandler(response) {
    window.location.reload();
  }

  return (
    <div>
      <Button
        id={styles["table-add-record-btn"]}
        className="float-end"
        onClick={() => navigate("add-patient")}
        startIcon={<img src="assets/plus-icon.svg" alt="Add icon" />}
        variant="contained"
      >
        Add Patient
      </Button>
      {fetchPatientsAPI.status === "pending" ? (
        <div className="text-center">
          <CircularProgress
            className="text-center"
            sx={{
              mt: 50,
            }}
          />
        </div>
      ) : (
        <ThemeProvider theme={DATA_TABLE_THEME}>
          <MUIDataTable
            title={"Patients"}
            data={patientList}
            columns={dataTableColumns}
            options={dataTableOptions}
          />
        </ThemeProvider>
      )}
      <PopUpModal ref={popUpModalRef} modalWidth={400}>
        <ConfirmActionModal
          content="Do you want to confirm archiving the patient?"
          confirmActionHandler={() => {
            archivePatient(archivingPatientId);
            popUpModalRef.current.closeModal();
          }}
          onCloseHandler={() => popUpModalRef.current.closeModal()}
        />
      </PopUpModal>
    </div>
  );

  function transformJsonData(
    id,
    fullName,
    phone,
    email,
    dob,
    createdAt,
    index
  ) {
    return {
      id,
      index,
      fullName,
      phone,
      email,
      dob,
      createdAt: DateUtils.formatDateTime(createdAt),
      actions: <ActionsCell patientId={id} />,
    };
  }

  function ActionsCell({ patientId }) {
    const actions = {
      startItems: [
        {
          component: <EditIcon fontSize="inherit" color="secondary" />,
          onClickHandler: () => navigate(patientId + "/profile"),
        },
        {
          component: (
            <Tooltip title="Archive Patient">
              <ArchiveIcon fontSize="inherit" color="warning" />
            </Tooltip>
          ),
          onClickHandler: () => {
            setArchivingPatientId(patientId);
            popUpModalRef.current.openModal();
          },
        },
      ],
      menuItems: [
        {
          label: "Send Registration Email",
          onClickHandler: () => sendRegistrationEmail(patientId),
        },
      ],
    };

    return <TableRowActionsCell actions={actions} />;
  }
}

const dataTableColumns = [
  {
    name: "id",
    label: "Patient ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "fullName",
    label: "Full Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "email",
    label: "Email ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "phone",
    label: "Phone",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "createdAt",
    label: "Created At",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      download: false,
    },
  },
];
