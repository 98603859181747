import { useRef, useState } from "react";
import { Typography, Paper, Button, Divider } from "@mui/material";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import ReactToPrint from "react-to-print";

import DateUtils from "utils/DateUtils";

const StimFormReport = ({ reportData }) => {
  const targetRef = useRef();
  const [isPrintLoading, setIsPrintLoading] = useState(false);

  return (
    <Paper elevation={0} className="py-2 px-2">
      <div className="d-flex justify-content-between p-3">
        <Typography variant="h2">
          {reportData.ageGroup} Report Generated
        </Typography>
        <div>
          {/* <Button
            type="button"
            onClick={null}
            startIcon={<MailOutlineRoundedIcon />}
            variant="outlined"
            className="me-3"
          >
            Email Patient
          </Button> */}
          <ReactToPrint
            trigger={() => (
              <Button
                type="button"
                startIcon={<DownloadRoundedIcon />}
                variant="contained"
                disabled={isPrintLoading}
              >
                Download Report
              </Button>
            )}
            content={() => targetRef.current}
            onBeforeGetContent={() => {
              setIsPrintLoading(true);
              return Promise.resolve();
            }}
            onAfterPrint={() => {
              setIsPrintLoading(false);
            }}
          />
        </div>
      </div>
      <Report targetRef={targetRef} reportData={reportData} />
    </Paper>
  );
};

const Report = ({ targetRef, reportData }) => {
  const genderPronoun = reportData.gender === "Male" ? "he" : "she";
  const genderAdjective = reportData.gender === "Male" ? "his" : "her";

  return (
    <Paper ref={targetRef} className="p-5" elevation={2}>
      <header className="text-center">
        <img
          src="../../assets/melillo_method_image.png"
          width={100}
          alt="Dr. Robert Melillo"
        />
        <Typography variant="h1">
          {reportData.program?.toUpperCase()} HEMISPHERE HOME PROGRAM
        </Typography>
        <Typography variant="h3" className="mt-3">
          Patient :{" "}
          <u>
            {reportData.firstName} {reportData.lastName}
          </u>
        </Typography>
        <Typography variant="h3" className="mt-1">
          Initial Evaluation :{" "}
          <u>{DateUtils.formatDateTimeToDate(reportData.evaluationDate)}</u>
        </Typography>
      </header>
      <main className="p-5">
        {/* Primitive Reflexes */}
        {reportData.sections.primitiveReflexes.length > 0 && (
          <>
            <section className="py-3">
              <Typography variant="h3">
                Primitive Reflexes Exercises/Stimulation
              </Typography>
              {reportData.ageGroup === "Child" ? (
                <Typography variant="body1" className="mt-2">
                  Based upon the paperwork completed, the physical examination
                  and testing, we confirmed that {reportData.firstName} has a{" "}
                  {reportData.program} hemisphere deficit, and most all
                  primitive reflexes are still present. Research has clearly
                  shown that retained Primitive Reflexes will lead to imbalances
                  in the brain, so it is important for the family to work on
                  these as part of {reportData.firstName}’s home program. These
                  are recommended to be done twice daily with{" "}
                  {reportData.firstName}.<br></br>
                  <br></br>Here is a video that goes through the home therapy
                  equipment first and then the Primitive Reflex stimulation
                  exercise instructions to help with the home program:
                  <br></br>
                  {reportData.program === "left" ? (
                    <a href="https://www.dropbox.com/scl/fi/u82gjd7wxhp2dolmqxt71/Bridget-Left-Brain-Program-Kit-and-Primitive-Reflex-Examples.mov?rlkey=48avnd3w6jjew6jq0915urvwz&st=0lknlbyc&dl=0">
                      https://www.dropbox.com/scl/fi/u82gjd7wxhp2dolmqxt71/Bridget-Left-Brain-Program-Kit-and-Primitive-Reflex-Examples.mov?rlkey=48avnd3w6jjew6jq0915urvwz&st=0lknlbyc&dl=0
                    </a>
                  ) : (
                    <a href="https://www.dropbox.com/scl/fi/lp5wyg9hlkdwwefqbwjhx/bridgette-right-brain-program-kit-and-priminitive-reflexes.mov?rlkey=va82l817cp1r24ueh561bhec7&dl=0">
                      https://www.dropbox.com/scl/fi/lp5wyg9hlkdwwefqbwjhx/bridgette-right-brain-program-kit-and-priminitive-reflexes.mov?rlkey=va82l817cp1r24ueh561bhec7&dl=0
                    </a>
                  )}
                </Typography>
              ) : (
                <Typography variant="body1" className="mt-2">
                  Based upon the paperwork completed, the physical examination
                  and testing, we confirmed that {reportData.firstName} has a{" "}
                  {reportData.program} hemisphere deficit, and most all
                  primitive reflexes are still present. Research has clearly
                  shown that retained Primitive Reflexes will lead to imbalances
                  in the brain, so it is important to work on these as part of{" "}
                  {reportData.firstName}’s home program. These are recommended
                  to be done twice daily with {reportData.firstName}.<br></br>
                  <br></br>Here is a video that goes through the home therapy
                  equipment first and then the Primitive Reflex stimulation
                  exercise instructions to help with the home program:
                  <br></br>
                  <a href="https://www.dropbox.com/s/oy3mmi054icw8ql/Melillo%20Adult%20ADHD%20-%205-02%20-%20The%20Adult%20Primitive%20Reflex%20Exercises%20Level%201.mp4?dl=0">
                    https://www.dropbox.com/s/oy3mmi054icw8ql/Melillo%20Adult%20ADHD%20-%205-02%20-%20The%20Adult%20Primitive%20Reflex%20Exercises%20Level%201.mp4?dl=0
                  </a>
                </Typography>
              )}

              <div className="mt-2">
                {reportData.sections.primitiveReflexes.map((value, index) => (
                  <div className="mt-2">
                    <PrimitiveReflex
                      key={`pm-${index}`}
                      firstName={reportData.firstName}
                      value={value}
                      ageGroup={reportData.ageGroup}
                      genderPronoun={genderPronoun}
                      genderAdjective={genderAdjective}
                    />
                  </div>
                ))}
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Core Stability */}
        <section className="py-3">
          <Typography variant="h3">Core Stability Exercises</Typography>
          <Typography variant="body1" className="mt-2">
            These are important for {reportData.firstName} to create spinal
            stability, and build core strength. The spine carries a lot of
            information to the brain, so this is important for many reasons.
            These will be part of {reportData.firstName}’s daily home program
            also.
          </Typography>
          {reportData.sections.coreStability.prone.length > 0 && (
            <div className="mt-4">
              <Typography variant="h4">Prone</Typography>
              {reportData.sections.coreStability.prone.map((level, index) => (
                <CSProne level={level} key={`prone-${index}`} />
              ))}
            </div>
          )}
          {reportData.sections.coreStability.lateral.length > 0 && (
            <div className="mt-4">
              <Typography variant="h4">Lateral Core</Typography>
              {reportData.sections.coreStability.lateral.map((level, index) => (
                <CSLateral level={level} key={`lateral-${index}`} />
              ))}
            </div>
          )}
          {reportData.sections.coreStability.supine.length > 0 && (
            <div className="mt-4">
              <Typography variant="h4">Supine</Typography>
              {reportData.sections.coreStability.supine.map((level, index) => (
                <CSSupine level={level} key={`supine-${index}`} />
              ))}
            </div>
          )}
          {reportData.sections.coreStability.curlUps.length > 0 && (
            <div className="mt-4">
              <Typography variant="h4">Curl Ups</Typography>
              {reportData.sections.coreStability.curlUps.map((level, index) => (
                <CSCurlUps level={level} key={`curlUps-${index}`} />
              ))}
            </div>
          )}
          {reportData.sections.coreStability.pushUps.length > 0 && (
            <div className="mt-4">
              <Typography variant="h4">Push Ups</Typography>
              {reportData.sections.coreStability.pushUps.map((level, index) => (
                <CSPushUps level={level} key={`pushUps-${index}`} />
              ))}
            </div>
          )}
          {reportData.sections.coreStability.pullUps.length > 0 && (
            <div className="mt-4">
              <Typography variant="h4">Pull Ups</Typography>
              {reportData.sections.coreStability.pullUps.map((level, index) => (
                <div className="mt-2">
                  <CSPullUps level={level} key={`pullUps-${index}`} />
                </div>
              ))}
              <div className="d-flex justify-content-around">
                <img
                  src="../../user-forms/doctor-assessment/exercise-images/core_pullups_1.png"
                  alt="exercise"
                  width={250}
                  className="m-4"
                />{" "}
                <img
                  src="../../user-forms/doctor-assessment/exercise-images/core_pullups_2.png"
                  alt="exercise"
                  width={250}
                  className="m-4"
                />
              </div>
            </div>
          )}
        </section>

        {/* Strength Exercises */}
        {reportData.sections.strengthExercises.length > 0 && (
          <>
            <section className="py-3">
              <Typography variant="h3">Strength Exercises</Typography>
              <div className="mt-4">
                {reportData.sections.strengthExercises.map(
                  (exercise, index) => (
                    <StrengthExercise
                      key={`se-${index}`}
                      exercise={exercise}
                      genderPronoun={genderPronoun}
                      genderAdjective={genderAdjective}
                    />
                  )
                )}
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Brachiation */}
        <section className="py-3">
          <Typography variant="h3">Brachiation</Typography>
          <Typography variant="body1" className="mt-2">
            Recommend the family purchase a doorway chin up bar for the home
            program. Level 1 will be for him to be able to hang from the bar,
            unsupported (on {genderAdjective} own). At first the goal is 30
            seconds and then the goal is 60 seconds at once before moving up to
            the next level.
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src="../../user-forms/doctor-assessment/exercise-images/core_pullups_1.png"
              alt="exercise"
              width={250}
              className="m-4"
            />
            <img
              src="../../user-forms/doctor-assessment/exercise-images/core_pullups_2.png"
              alt="exercise"
              width={250}
              className="m-4"
            />
          </div>
        </section>
        <Divider />

        {/* Balance Exercises */}
        {reportData.sections.balanceExercise && (
          <>
            <section className="py-3">
              <Typography variant="h3">Balance Exercises</Typography>
              <div className="mt-4">
                <BalanceExercise
                  level={reportData.sections.balanceExercise}
                  program={reportData.program}
                />
              </div>
              <div className="d-flex justify-content-around">
                <img
                  src="../../user-forms/doctor-assessment/exercise-images/balance_1.png"
                  alt="exercise"
                  width={250}
                  className="m-4"
                />
                <img
                  src="../../user-forms/doctor-assessment/exercise-images/balance_2.png"
                  alt="exercise"
                  width={250}
                  className="m-4"
                />
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Vestibular Exercises */}
        {reportData.sections.vestibularExercises.length > 0 && (
          <>
            <section className="py-3">
              <Typography variant="h3">Vestibular Exercises</Typography>
              <div className="mt-2">
                {reportData.sections.vestibularExercises.map((value, index) => (
                  <div className="mt-2">
                    <VestibularExercise
                      key={`vestibular-${index}`}
                      firstName={reportData.firstName}
                      value={value}
                      program={reportData.program}
                      genderAdjective={genderAdjective}
                    />
                  </div>
                ))}
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Optokinetic Reflex Exercises */}
        <section className="py-3">
          <Typography variant="h3">Optokinetic Reflex Exercises</Typography>
          <Typography variant="body1" className="mt-2">
            Parents will use the Optodrum app (image below) downloaded onto a
            phone. They will use the black and white stripes going to the{" "}
            {reportData.program}
            and {reportData.program === "left" ? "up" : "down"} only for 30
            secs: Turn your phone and hold it about 6-8” away from him and{" "}
            {genderPronoun} will follow the stripes across the screen in that
            direction for 2 times per day.
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src="../../user-forms/doctor-assessment/exercise-images/optokinetic_1.png"
              alt="exercise"
              width={200}
              className="m-4"
            />
            <img
              src="../../user-forms/doctor-assessment/exercise-images/optokinetic_2.png"
              alt="exercise"
              width={200}
              className="m-4"
            />
          </div>
        </section>
        <Divider />

        {/* Hemispheric Sensory Stimulus */}
        <section className="py-3">
          <Typography variant="h3">Hemispheric Sensory Stimulus</Typography>
          <Typography variant="body1" className="mt-2">
            Recommend that {reportData.firstName} receive regular chiropractic
            manipulations also as part of the therapy program by a well-trained
            professional. These will be done to the{" "}
            {reportData.program === "left" ? "right" : "left"} side only of the
            cervical spine and the right and left side of the thoracic and
            lumbar spine.
          </Typography>
        </section>
        <Divider />

        {/* Light Stimulation */}
        {reportData.sections.eyeLights.length > 0 && (
          <>
            <section className="py-3">
              <Typography variant="h3">Light Stimulation</Typography>
              <div className="mt-4">
                {reportData.sections.eyeLights.map((eyelight, index) => (
                  <LSEyelights
                    eyelight={eyelight}
                    firstName={reportData.firstName}
                    genderPronoun={genderPronoun}
                    genderAdjective={genderAdjective}
                    key={`eyelight-${index}`}
                  />
                ))}
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Sound Therapy */}
        {reportData.sections.soundTherapy.length > 0 && (
          <>
            <section className="py-3">
              <Typography variant="h3">Sound Therapy</Typography>
              <div className="mt-2">
                {reportData.sections.soundTherapy.map((value, index) => (
                  <div className="mt-2">
                    <SoundTherapy
                      value={value}
                      firstName={reportData.firstName}
                      program={reportData.program}
                      genderPronoun={genderPronoun}
                      genderAdjective={genderAdjective}
                      key={`sound-${index}`}
                    />
                  </div>
                ))}
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* TENs Unit */}
        <section className="py-3">
          <Typography variant="h3">Tactile - TENs Unit</Typography>
          <Typography variant="body1" className="mt-4">
            This is another item that I recommend {reportData.firstName} to use
            as part of the daily home program. Some TENS units have 2 pads and
            others only have 1: The pad(s) will be placed on the{" "}
            {reportData.program === "left" ? "right" : "left"} upper back
            between the shoulder blade and the spine. Starting with 10-20
            minutes daily and every other week add 10 minutes 2 times a day
            until the maximum time of 60 minutes. Again, this can be used for 2
            times a day and combine while using the other equipment at the same
            time for more {reportData.program} brain sensory stimulation.
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src={`../../user-forms/doctor-assessment/exercise-images/tens_unit_${reportData.program}.png`}
              alt="exercise"
              width={200}
              className="m-4"
            />
          </div>
          <Typography variant="body1" className="mt-2">
            <b>Vibration</b> - The family can also use a small hand-held
            vibration device or a Rezzimax for the home program. They will use
            this up and down the{" "}
            {reportData.program === "left" ? "right" : "left"} arm and{" "}
            {reportData.program === "left" ? "right" : "left"} leg for 1 to 2
            minutes each for 1 to 2 times per day for more sensory stimulation.
          </Typography>
        </section>
        <Divider />

        {/* Smells */}
        {reportData.sections.smells.length > 0 && (
          <>
            <section className="py-3">
              <Typography variant="h3">
                Smells (Olfactory Stimulation)
              </Typography>
              <div className="mt-4">
                {reportData.sections.smells.map((value, index) => (
                  <Smell
                    value={value}
                    program={reportData.program}
                    key={`smell-${index}`}
                  />
                ))}
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Rhythmic/Timing Movements */}
        {reportData.sections.rhythmicMovements.length > 0 && (
          <>
            <section className="py-3">
              <Typography variant="h3">Rhythmic/Timing Movements</Typography>
              <div className="mt-4">
                <Typography variant="body1">
                  Using the Metrotimer App again, I will recommend that{" "}
                  {reportData.firstName} start doing these exercises also as
                  part of the home program to help with {genderAdjective} timing
                  and processing.
                  <br></br>
                  <a href="https://www.dropbox.com/s/3wdfe3p608j0ehf/Metrotime%20Instructions.mov?dl=0">
                    https://www.dropbox.com/s/3wdfe3p608j0ehf/Metrotime%20Instructions.mov?dl=0
                  </a>
                </Typography>
                {reportData.sections.rhythmicMovements.map((value, index) => (
                  <div className="mt-2">
                    <RhythmicMovement
                      value={value}
                      genderPronoun={genderPronoun}
                      genderAdjective={genderAdjective}
                      key={`rm-${index}`}
                    />
                  </div>
                ))}
                <div className="d-flex justify-content-around">
                  <img
                    src="../../user-forms/doctor-assessment/exercise-images/rhythmic_timing_1.png"
                    alt="exercise"
                    width={250}
                    className="m-4"
                  />
                  <img
                    src="../../user-forms/doctor-assessment/exercise-images/rhythmic_timing_2.png"
                    alt="exercise"
                    width={250}
                    className="m-4"
                  />
                </div>
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Brain Stimulation */}
        <section className="py-3">
          <Typography variant="h3">Brain Stimulation/Modulation</Typography>
          <Typography variant="body1" className="mt-4">
            <b>Transcranial Direct Current (Brain Driver)</b> – I also be
            recommended to use the Brain Driver as part of his home program. He
            can use this for starting with 10-20 minutes, twice daily and
            increasing the time every 2 weeks by 10 more minutes to the maximum
            time of 60 minutes at a time. Combine the Brain Driver while using
            the TENS, and eyelights at the same time for more{" "}
            {reportData.program} brain sensory stimulation. Review the
            instruction video which will show the red lead goes on the right
            side and black on the left side and always use with saline solution
            and wash out the pads each time after using.
            <br></br>
            <a href="https://www.dropbox.com/scl/fi/p9czerbvvyymufqr7olay/braindriver-rightbrain.mov?rlkey=1mgixy39c4cwlr6sd6rvo4awo&st=yrfyjdcr&dl=0">
              https://www.dropbox.com/scl/fi/p9czerbvvyymufqr7olay/braindriver-rightbrain.mov?rlkey=1mgixy39c4cwlr6sd6rvo4awo&st=yrfyjdcr&dl=0
            </a>
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src="../../user-forms/doctor-assessment/exercise-images/brain_stimulation_1.png"
              alt="exercise"
              width={300}
              className="m-4"
            />
            <img
              src="../../user-forms/doctor-assessment/exercise-images/brain_stimulation_2.png"
              alt="exercise"
              width={300}
              className="m-4"
            />
            <img
              src="../../user-forms/doctor-assessment/exercise-images/brain_stimulation_3.png"
              alt="exercise"
              width={300}
              className="m-4"
            />
          </div>
        </section>
        <Divider />

        {/* Cognitive Activities */}
        {(reportData.sections.cognitiveActivities.rightBrain.length > 0 ||
          reportData.sections.cognitiveActivities.leftBrain.length > 0) && (
          <>
            <section className="py-3">
              <Typography variant="h3">Cognitive Activities</Typography>
              <div className="mt-2">
                {reportData.sections.cognitiveActivities.rightBrain.length >
                  0 && (
                  <Typography variant="body1" className="mt-4">
                    <b>Right Brain</b> -{" "}
                    {reportData.sections.cognitiveActivities.rightBrain.join(
                      ", "
                    )}
                    . Age level jigsaw puzzles, the game Connect 4 and maze
                    books are right brain activities. A game of ring toss,
                    ladder ball or corn-hole are also right brain fun and can be
                    played outside or inside. If the family would like to
                    purchase additional cognitive or academic tools, we
                    recommend the following website: MaxScholar Workbooks &
                    ebooks The link provided has 14 reading comprehension
                    workbooks, which are a great recommendation for RHD patients
                    of different age levels.
                  </Typography>
                )}
              </div>
              <div className="mt-2">
                {reportData.sections.cognitiveActivities.leftBrain.length >
                  0 && (
                  <Typography variant="body1" className="mt-2">
                    <b>Left Brain</b> -{" "}
                    {reportData.sections.cognitiveActivities.leftBrain.join(
                      ", "
                    )}
                    . Any type of Memory games with Numbers, letters or words
                    are left-brain activities. Games like Scrabble, Cross word
                    puzzles, Boggle and also Tic-Tac-Toe and hangman are also
                    left brain to do for fun. If the family would like to
                    purchase additional cognitive or academic tools, we
                    recommend the following website: MaxScholar Workbooks &
                    ebooks There are sound cards, alpha chips, 7 workbooks on
                    words and 6 workbooks on phonics which are for spelling and
                    word reading. These can be great recommendations for LHD
                    patients.
                  </Typography>
                )}
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Vitamins */}
        {reportData.sections.vitamins.length > 0 && (
          <>
            <section className="py-3">
              <Typography variant="h3">Vitamin Recommendations</Typography>
              <div className="mt-4">
                {reportData.sections.vitamins.map((vitamin, index) => (
                  <Vitamin
                    vitamin={vitamin}
                    firstName={reportData.firstName}
                    key={`vitamin-${index}`}
                  />
                ))}
              </div>
            </section>
            <Divider />
          </>
        )}

        {/* Ending */}
        <section className="py-3">
          <Typography variant="body1" className="mt-2">
            It was great to meet {reportData.firstName}’s family and I am
            excited to get him started with the program because I think that he
            will do very well. The team really enjoyed working with him this
            week and everyone is excited to follow {genderAdjective} progress
            with the program. I will remind the family to schedule a virtual
            follow up with the team in about 4 to 6 weeks so they can check in
            and make any updates that are needed, and I will recommend that{" "}
            {reportData.firstName} return in 3-6 months for a full reevaluation
            after the family has been doing the home program daily. Thank you
            for the opportunity to be a part of {reportData.firstName}’s journey
            and I am excited to see {genderAdjective}
            progress.
          </Typography>
        </section>
      </main>
    </Paper>
  );
};

/**
 *  Stim Report Sections
 */

function PrimitiveReflex({
  value,
  program,
  firstName,
  ageGroup,
  genderPronoun,
  genderAdjective,
}) {
  if (value === "TLR") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>TLR</b> – {firstName} will be on the floor in a cannonball
          position, knees tucked into chest and arms wrapped around them. Parent
          will sit on floor next to him and flex the head and rock body forward
          as far as possible, then extend the head back and roll backwards that
          is one rep. Do a total of 10 reps.
        </Typography>
      );
    } else {
      return (
        <>
          <Typography variant="body1" className="mt-2">
            <b>TLR</b> – Cannonball – Hold knees into chest and wrap arms around
            legs. Bring your head up to your knees so that your shoulders should
            leave the ground as you curl into a tight ball, try to hold for 15
            seconds and release. Do this for 10 times.
            <br></br>
            <a href="https://www.dropbox.com/s/ytyu6zhmpzdm4ch/38%20-%20Tonic%20Labyrinthine%20Reflex%20clip.mp4?dl=0">
              https://www.dropbox.com/s/ytyu6zhmpzdm4ch/38%20-%20Tonic%20Labyrinthine%20Reflex%20clip.mp4?dl=0
            </a>
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src="../../user-forms/doctor-assessment/exercise-images/pr_tlr.png"
              alt="exercise"
              width={300}
              className="m-4"
            />
          </div>
        </>
      );
    }
  }

  if (value === "ASTNR") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>ASTNR</b> – Parent will hold {firstName}’s head in hands while{" "}
          {genderPronoun} is in a tabletop position, they will turn{" "}
          {genderAdjective} head to the {program === "left" ? "left" : "right"}{" "}
          10 times and then to the
          {program === "left" ? "right" : "left"} 10 times.
        </Typography>
      );
    } else {
      return (
        <>
          <Typography variant="body1" className="mt-2">
            <b>ASTNR</b> – Lizard. Lying on stomach, head turn to one side, the
            arm and left are extended straight on the side of head turn, arm and
            leg on other side are flexed. Start with head, lift the head turning
            it and the same time the arm and leg that were extended flexed and
            the opposite arm and leg extend. Then turn head again back to
            original position and arms and legs go back to staring points this
            is one rep. Do this for 10 times.
            <br></br>
            <a href="https://www.dropbox.com/s/aqnpvivs88wyzd4/39%20-%20Asymmetric%20Tonic%20Neck%20Reflex%20clip.mp4?dl=0">
              https://www.dropbox.com/s/aqnpvivs88wyzd4/39%20-%20Asymmetric%20Tonic%20Neck%20Reflex%20clip.mp4?dl=0
            </a>
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src="../../user-forms/doctor-assessment/exercise-images/pr_astnr.png"
              alt="exercise"
              width={350}
              className="m-4"
            />
          </div>
        </>
      );
    }
  }

  if (value === "Babinski") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Babinski</b> – Stroke up with handle end of paintbrush on the
          lateral side and bottom of {genderAdjective} foot for 20 times on the{" "}
          {program === "left" ? "right" : "left"} foot and then 10 times on the{" "}
          {program === "left" ? "left" : "right"} foot.
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Babinski</b> – Using a tennis ball, roll this back and forth on the
          bottom of the left foot for 20 times and right foot for 10 times.
        </Typography>
      );
    }
  }

  if (value === "Rooting") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Rooting</b> – Again, with a paint brush (small), Take a brush
          stroke from cheek down to corner of mouth and then across both lips 5
          x’s; then from Chin toward corner of mouth and across lips again 5
          x’s; repeat this on the other side of face. Also, can use back end of
          vibrating toothbrush vibrate over lips and then hold on back of tongue
          for 30 secs to 1 minute.
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Rooting</b> – The exercise for this will be to use a balloon and
          blow it up or suck through a straw for 10 times.
        </Typography>
      );
    }
  }

  if (value === "Snout Exercise") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Snout Exercise</b> – Parent will press on Philtrum (space between
          the nose and upper lip) for 10 times.
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Snout Exercise</b> – Using the handle of the paint brush and press
          on Philtrum (space between the nose and upper lip) for 10 times.
        </Typography>
      );
    }
  }

  if (value === "Spinal Galant") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Spinal Galant</b> – Parent will stroke down the side of {firstName}
          ’s spine with the handle side of the paint brush, go a little more
          lateral with reps do 10 times both sides.
        </Typography>
      );
    } else {
      return (
        <>
          <Typography variant="body1" className="mt-2">
            <b>Spinal Galant</b> – Angels in the snow - Lying on the back, start
            with arms at the side and legs together. Then, opening arms and legs
            together until hands touch at the top and legs are spread as far as
            possible apart at the same time. Then go back to original position
            with hands next to body and legs together, trying to arrive with the
            arms and legs at the same time in the position, that is 1 set. Do
            this for 10 times.
            <br></br>
            <a href="https://www.dropbox.com/s/0jwf9hc86xkbxsr/40%20-%20Spinal%20Galant%20Reflex%20clip.mp4?dl=0">
              https://www.dropbox.com/s/0jwf9hc86xkbxsr/40%20-%20Spinal%20Galant%20Reflex%20clip.mp4?dl=0
            </a>
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src="../../user-forms/doctor-assessment/exercise-images/pr_spinal_galant.png"
              alt="exercise"
              width={280}
              className="m-4"
            />
          </div>
        </>
      );
    }
  }

  if (value === "Symmetric Tonic Neck Exercise") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Symmetric Tonic Neck Exercise</b> – {firstName} will be on{" "}
          {genderAdjective} hands and knees and parent will hold him under the
          chin and on the crown of the head, and then move {genderAdjective}{" "}
          head up and down quickly through full range 10 times.
        </Typography>
      );
    } else {
      return (
        <>
          <Typography variant="body1" className="mt-2">
            <b>Symmetric Tonic Neck Exercise</b> – Cat Stretch – On hands and
            knees in a tabletop position, and will move the head all of the way
            up until neck is stretched back and then down quickly as far as
            possible with chin close to chest and eyes looking at knees: Through
            the full range up and down for 10 times.
            <br></br>
            <a href="https://www.dropbox.com/s/2t2zd4avijjyzn3/41%20-%20Symmetric%20Tonic%20Neck%20Reflex%20clip.mp4?dl=0">
              https://www.dropbox.com/s/2t2zd4avijjyzn3/41%20-%20Symmetric%20Tonic%20Neck%20Reflex%20clip.mp4?dl=0
            </a>
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src="../../user-forms/doctor-assessment/exercise-images/pr_tonic_neck.png"
              alt="exercise"
              width={300}
              className="m-4"
            />
          </div>
        </>
      );
    }
  }

  if (value === "Moro") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Moro</b> - Lying face up, hold {genderAdjective} head and bend it
          forward, then quickly move the head down toward the ground quickly
          head back quickly about 1-2 inches, (do not hit the ground or table)
          Do this 10 times and you can also stand behind him and loudly clap
          behind
          {genderAdjective} head to make him jump or startle as that stimulates
          this reflex also.
        </Typography>
      );
    } else {
      return (
        <>
          <Typography variant="body1" className="mt-2">
            <b>Moro</b> - Exercise for this is called Starfish - Sitting on
            chair, start from fetal position, hands curled in a fist and head
            bent forward, the right wrist over left wrist and the right ankle
            over left (crisscrossed). Open all the way up, bending head all the
            way back with arms and legs stretched out to full extension, then go
            back to fetal position but this time crossing left wrist over right
            and left ankle over right. Doing 1 rep for each side is considered
            1. Repeat and do this for a total of 10 times.
            <br></br>
            <a href="https://www.dropbox.com/s/1virpgozw28r7m8/37%20-%20Moro%20Reflex%20clip.mp4?dl=0">
              https://www.dropbox.com/s/1virpgozw28r7m8/37%20-%20Moro%20Reflex%20clip.mp4?dl=0
            </a>
          </Typography>
          <div className="d-flex justify-content-around">
            <img
              src="../../user-forms/doctor-assessment/exercise-images/pr_moro.png"
              alt="exercise"
              width={300}
              className="m-4"
            />
          </div>
        </>
      );
    }
  }

  if (value === "Palmer Grasp") {
    if (ageGroup === "Child") {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Palmer Grasp</b> - use the handle end of the paint brush or
          Vibration to make X on {genderAdjective} hand for 20 times on the
          {program === "left" ? "right" : "left"} hand and then 10 times on{" "}
          {genderAdjective} {program === "left" ? "left" : "right"} hand.
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1" className="mt-2">
          <b>Palmer Grasp</b> - Use a tennis ball or a squishy stress ball,
          holding it in your hand, squeeze it for 20 times with the left hand
          and 10 times with the right hand.
        </Typography>
      );
    }
  }

  return null;
}

function StrengthExercise({ exercise, genderPronoun, genderAdjective }) {
  if (exercise === "Push Up Planks")
    return (
      <Typography variant="body1" className="mt-2">
        <b>Push Up Planks</b> –
      </Typography>
    );

  if (exercise === "Wheelbarrow /Parachute Reflex")
    return (
      <>
        <Typography variant="body1" className="mt-2">
          <b>Wheelbarrow /Parachute Reflex</b> - Hands are on the ground, and
          you are holding {genderAdjective} legs up off the ground and{" "}
          {genderPronoun} will be supporting {genderAdjective} weight on{" "}
          {genderAdjective} arms. (this is Parachute reflex). The first goal
          would be to have him support {genderAdjective} weight on{" "}
          {genderAdjective} arms while parent holds {genderAdjective} legs up.
          Once {genderPronoun} can hold that position for 30 seconds, then{" "}
          {genderPronoun} can try to take a step with {genderAdjective} hands
          and the next level will be for him to walk with {genderAdjective}
          hands in this position for 30 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/vcc6unxaauxkcjob0091o/wheel-barrow.MOV?rlkey=eg99njtrfqxgw777z5x62uvrl&dl=0">
            https://www.dropbox.com/scl/fi/vcc6unxaauxkcjob0091o/wheel-barrow.MOV?rlkey=eg99njtrfqxgw777z5x62uvrl&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );

  if (exercise === "Push Ups")
    return (
      <Typography variant="body1" className="mt-2">
        <b>Push Ups</b> –
      </Typography>
    );

  if (exercise === "Curl ups with combined Hands Pulling Exercise")
    return (
      <Typography variant="body1" className="mt-2">
        <b>Curl ups with combined Hands Pulling Exercise</b> –
      </Typography>
    );

  if (exercise === "Curl Ups")
    return (
      <Typography variant="body1" className="mt-2">
        <b>Curl Ups</b> –
      </Typography>
    );

  return null;
}

function VestibularExercise({ value, program, firstName, genderAdjective }) {
  if (value === "Head Thrust")
    return (
      <>
        <Typography variant="body1" className="mt-2">
          <b>Head Thrust</b> - Partner will sit in front of patient and hold
          sides of head in their hands and patient should try to look forward
          while partner quickly turns their head to the{" "}
          {program === "left" ? "right" : "left"}, slowly back to center, then
          quickly back to the {program === "left" ? "right" : "left"}, repeating
          this for 10 times as shown in the video.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/h8v1q2u3khsmed1egokgi/Head-Thrust-Instructions.mov?rlkey=1dk7k55uzutc8lrifj09zgtgn&dl=0">
            https://www.dropbox.com/scl/fi/h8v1q2u3khsmed1egokgi/Head-Thrust-Instructions.mov?rlkey=1dk7k55uzutc8lrifj09zgtgn&dl=0
          </a>
        </Typography>
      </>
    );
  if (value === "Voluntary Head Turn")
    return (
      <>
        <Typography variant="body1" className="mt-2">
          <b>Voluntary Head Turn</b> - Holding something like a pencil out in
          front of {genderAdjective}, or standing in front of a mirror,{" "}
          {firstName} will focus {genderAdjective}
          eyes on the oject and turn {genderAdjective} head as far to the{" "}
          {program === "left" ? "right" : "left"} as possible without loosing
          focus of the item in front of {genderAdjective} with their eyes, then
          turn head back to neutral (center). Do this for 10 times to the{" "}
          {program === "left" ? "right" : "left"}
          side only.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/vestibular_voluntary_head.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  if (value === "Spinning")
    return (
      <>
        <Typography variant="body1" className="mt-2">
          <b>PRNG (Spinning)</b>- Spinning (Fast to the right, clockwise): 10
          rotations for 2 seconds per rotation, repeat until you can see
          Nystagmus (beating) of eyes for 2 times a day.
          <br></br>
          <a href="https://www.dropbox.com/s/gt5e0pm9iv5oe1x/Spinning%20and%20Eye%20Movements.MOV?dl=0">
            https://www.dropbox.com/s/gt5e0pm9iv5oe1x/Spinning%20and%20Eye%20Movements.MOV?dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/vestibular_spinning_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
          <img
            src="../../user-forms/doctor-assessment/exercise-images/vestibular_spinning_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  return null;
}

function BalanceExercise({ level, program }) {
  if (level === "1")
    return (
      <Typography variant="body1">
        <b>Level 1 - Rhomberg Exercise</b> : Feet together move head back and
        forth with eyes open. The goal is to stand still with no falls for 30
        seconds 1-2 times a day.
      </Typography>
    );
  if (level === "2")
    return (
      <Typography variant="body1">
        <b>Level 2 - Rhomberg Exercise</b> : Feet together, move head back and
        forth with eyes closed. Goal is to stand still with no falls for 30
        seconds 1-2 times a day.
      </Typography>
    );
  if (level === "3")
    return (
      <Typography variant="body1">
        <b>Level 3 - Manns Exercise</b> : Stand one foot in front of the other,
        with eyes open. Goal is to stand still with no falls for 30 seconds.
      </Typography>
    );
  if (level === "4")
    return (
      <Typography variant="body1">
        <b>Level 4 - Manns Exercise</b> : Stand one foot in front of the other,
        with eyes closed. Goal is to stand still with no falls for 30 seconds.
      </Typography>
    );
  if (level === "5")
    return (
      <Typography variant="body1">
        <b>Level 5 - Stork/One Leg Stand Exercise</b> : Stand on{" "}
        {program === "right" ? "left" : "right"} leg with eyes open for 30
        seconds, then switch and stand on{" "}
        {program === "right" ? "left" : "right"} leg for 30 seconds with eyes
        open. Goal is to stand still with no falls on each side. 1-2 times a
        day.
      </Typography>
    );
  if (level === "6")
    return (
      <Typography variant="body1">
        <b>Level 6 - Stork/One Leg Stand Exercise</b> : Stand on{" "}
        {program === "right" ? "left" : "right"} leg with eyes closed for 30
        seconds, then switch and stand on{" "}
        {program === "right" ? "left" : "right"} leg for 30 seconds with eyes
        closed. Goal is to stand still with no falls on each side. 1-2 times a
        day
      </Typography>
    );
  return null;
}

function CSProne({ level }) {
  if (level === "Level 1") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 1 : </b>Starting on the floor and Lay face down, lift each
          limb separately and lift head up, hold it perfectly straight as long
          as possible. Goal is 15 seconds for each limb 1-2 times a day.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/23fmdrdi30lsk3sgm5prv/Prone-Level-1.MOV?rlkey=42okktkrc2h36ej1pzukujeev&st=3vrl9zl1&dl=0">
            https://www.dropbox.com/scl/fi/23fmdrdi30lsk3sgm5prv/Prone-Level-1.MOV?rlkey=42okktkrc2h36ej1pzukujeev&st=3vrl9zl1&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 2") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 2 : </b>Starting on the floor and Lay face down, lift each
          limb separately and lift head up, hold it perfectly straight for 30
          seconds for each limb. <br></br>
          <a href="https://www.dropbox.com/scl/fi/wer3tfw576x6s0byb9gfh/Prone-Level-2.MOV?rlkey=shtq2umwqudlc1b09u2wu2xcu&st=3pm7ctc3&dl=0">
            https://www.dropbox.com/scl/fi/wer3tfw576x6s0byb9gfh/Prone-Level-2.MOV?rlkey=shtq2umwqudlc1b09u2wu2xcu&st=3pm7ctc3&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 3") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 3 : </b>Starting on the floor and Lay face down, lift each
          limb separately and lift head up, hold it perfectly straight for 60
          seconds for each limb.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/q7wn9ewixeq4td6f5etqy/Prone-Level-3.MOV?rlkey=zxhlnk6s407jgir3bacnlcapf&st=9pk8ft28&dl=0">
            https://www.dropbox.com/scl/fi/q7wn9ewixeq4td6f5etqy/Prone-Level-3.MOV?rlkey=zxhlnk6s407jgir3bacnlcapf&st=9pk8ft28&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 4") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 4 : </b>Starting on the floor and Lay face down, lift up
          opposite arm and leg, and lift head up also. Hold it perfectly
          straight, switch sides and do other arms and leg: Goal is 30 seconds
          for each side.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/lqnrre25kl691qzstod04/Prone-Level-4.MOV?rlkey=7jpax9y1tt6k1gevywofhl4hx&st=4k812tev&dl=0">
            https://www.dropbox.com/scl/fi/lqnrre25kl691qzstod04/Prone-Level-4.MOV?rlkey=7jpax9y1tt6k1gevywofhl4hx&st=4k812tev&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 5") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 5 : </b> Starting on the floor and Lay face down, lift up
          opposite arm and leg, and lift head up also. Hold it perfectly
          straight, switch sides and do other arms and leg: Goal is 60 seconds
          for each side.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/yd5f6b38zhpzpe7l3seze/Prone-Level-5.MOV?rlkey=tufpn9alnopj9elvsxmh565wv&st=9kamn4wg&dl=0">
            https://www.dropbox.com/scl/fi/yd5f6b38zhpzpe7l3seze/Prone-Level-5.MOV?rlkey=tufpn9alnopj9elvsxmh565wv&st=9kamn4wg&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 6") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 6 : </b>(Superman) Lay face down, lift arms, legs and head
          all at the same time, hold as still and straight: Goal is 30 seconds
          for 1-2 times a day.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/sq1ylocilgodgocot0czm/Prone-Level-6.MOV?rlkey=vzuxmvevgkbr7qqf05vgnz95f&st=y4177k7m&dl=0">
            https://www.dropbox.com/scl/fi/sq1ylocilgodgocot0czm/Prone-Level-6.MOV?rlkey=vzuxmvevgkbr7qqf05vgnz95f&st=y4177k7m&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_4.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 7") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 7 : </b>(Superman) Lay face down, lift arms, legs and head
          all at the same time, hold as still and straight: Goal is 60 seconds
          for 1-2 times a day.
          <br></br>
          <a
            href="https://www.dropbox.com/scl/fi/z79ohtvikuis5kv4i9iep/Prone-Level-7.MOV?rlkey=poffl865zntya6ivbh0gpl9oy&st=k7hsq3yv&dl=0
"
          >
            https://www.dropbox.com/scl/fi/z79ohtvikuis5kv4i9iep/Prone-Level-7.MOV?rlkey=poffl865zntya6ivbh0gpl9oy&st=k7hsq3yv&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_4.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 8") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 8 : </b>This is called the Sorenson Hold. Laying on a bench
          or a bed, partner will hold feet stable and patient will lift up to
          extend back with arms crossed over chest and hold for 1 minute.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_prone_5.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 9") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 9 : </b>Sorenson Hold. Laying on a bench or a bed, a partner
          will hold feet stable and patient will lift up to extend back with
          arms crossed over chest and hold for 2 minutes.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/r17ow7z5gk656jprxsl7c/Prone-Level-9.MOV?rlkey=q9fk6c3z3z8pkp6zdc4yncs73&st=fnuwjj1f&dl=0">
            https://www.dropbox.com/scl/fi/r17ow7z5gk656jprxsl7c/Prone-Level-9.MOV?rlkey=q9fk6c3z3z8pkp6zdc4yncs73&st=fnuwjj1f&dl=0
          </a>
        </Typography>
      </>
    );
  }
  if (level === "Level 10") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 10 : </b>Sorenson Hold. Laying on a bench or a bed, a partner
          will hold feet stable and patient will lift up to extend back with
          arms crossed over chest and hold for 3 minutes.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/r17ow7z5gk656jprxsl7c/Prone-Level-9.MOV?rlkey=q9fk6c3z3z8pkp6zdc4yncs73&st=fnuwjj1f&dl=0">
            https://www.dropbox.com/scl/fi/r17ow7z5gk656jprxsl7c/Prone-Level-9.MOV?rlkey=q9fk6c3z3z8pkp6zdc4yncs73&st=fnuwjj1f&dl=0
          </a>
        </Typography>
      </>
    );
  }

  return null;
}

function CSLateral({ level }) {
  if (level === "Level 1") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 1 : </b>Laying on the side, Legs and body should be straight.
          Both hands on the floor along with bottom arm and elbow. Both feet
          should be on the ground. Raise the top leg and hold in a stable
          position. Reverse and do other side. Goal is 30 seconds each side.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/yohhn8x0f8my9n0n34yzi/Lateral-Level-1.MOV?rlkey=205pr0cdr244t3l5qyayo8rw0&st=o0mdl8tj&dl=0">
            https://www.dropbox.com/scl/fi/yohhn8x0f8my9n0n34yzi/Lateral-Level-1.MOV?rlkey=205pr0cdr244t3l5qyayo8rw0&st=o0mdl8tj&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 2") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 2 : </b>Side Plank with bent knees – Both hands on floor
          along with bottom arm and elbow, knees are bent and lift hips of the
          ground and hold in a stable position for 30 seconds and then switch
          sides.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/wmnamr2rc8zwdk8wavkbr/Lateral-Level-2.MOV?rlkey=tu7l9jnarfmp9y6jjonwd7kh2&st=55qtb8mg&dl=0">
            https://www.dropbox.com/scl/fi/wmnamr2rc8zwdk8wavkbr/Lateral-Level-2.MOV?rlkey=tu7l9jnarfmp9y6jjonwd7kh2&st=55qtb8mg&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 3") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 3 : </b>Legs and body should be straight. Both hands are on
          the floor along with the bottom arm and elbow. Feet at this level will
          be with the outside of bottom foot on the floor and inside of top foot
          also on the floor as shown below. Raise hips off the ground to make a
          lateral bridge, hold in a stable position, reverse and do other side.
          Goal is 30 seconds on each side.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/dewpvxxb0akoqxrmtu48y/Lateral-Level-3.MOV?rlkey=u94i1fejqhh3z9mp7mqnc9hws&st=49589nnc&dl=0">
            https://www.dropbox.com/scl/fi/dewpvxxb0akoqxrmtu48y/Lateral-Level-3.MOV?rlkey=u94i1fejqhh3z9mp7mqnc9hws&st=49589nnc&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 4") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 4 : </b>Legs and body should be straight. Both hands are on
          the floor along with the bottom arm and elbow. Feet at this level will
          be stacked. Raise hips off the ground for a lateral bridge… Hold as
          long as possible. Reverse and do other side. Goal is 60 seconds each
          side. 1-2 times a day.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_7.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 5") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 5 : </b> Weighted Side plank with a weighted vest on: Legs
          and body should be straight. Both hands are on the floor along with
          the bottom arm and elbow. Feet at this level will be stacked. Raise
          hips off the ground for a lateral bridge… Hold as long as possible.
          Reverse and do other side. Goal is 60 seconds each side. 1-2 times a
          day.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/lhfr4s2yc4yaaq3bu5ll4/Lateral-Level-5.MOV?rlkey=h9dwl2fgjgdyeuzaa83sfptos&st=q9h81c4k&dl=0">
            https://www.dropbox.com/scl/fi/lhfr4s2yc4yaaq3bu5ll4/Lateral-Level-5.MOV?rlkey=h9dwl2fgjgdyeuzaa83sfptos&st=q9h81c4k&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_7.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 6") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 6 : </b>On side, hands on floor along with bottom arm and
          elbow, feet will start off by being stacked. Then lift the hips and
          the top leg – the goal is for 30 seconds. Then switch sides and do
          with opposite leg.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/mkxujj044da3wwcoxiubg/Lateral-Level-6.MOV?rlkey=3uytuxltvrt4dvsr1c0b949a8&st=ju9m0o09&dl=0">
            https://www.dropbox.com/scl/fi/mkxujj044da3wwcoxiubg/Lateral-Level-6.MOV?rlkey=3uytuxltvrt4dvsr1c0b949a8&st=ju9m0o09&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_4.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 7") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 7 : </b>On side, hands on floor along with bottom arm and
          elbow, feet will start off by being stacked. Then lift the hips, The
          top arm and the top leg – the goal is for 30 seconds. Then switch
          sides and do with opposite side.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/p2k6fkhm88hcwk913y146/Lateral-Level-7.MOV?rlkey=4132uvwo01hkh9qynsn5fzsks&st=ncwixcaq&dl=0">
            https://www.dropbox.com/scl/fi/p2k6fkhm88hcwk913y146/Lateral-Level-7.MOV?rlkey=4132uvwo01hkh9qynsn5fzsks&st=ncwixcaq&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_5.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 8") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 8 : </b>Laying on Side with bottom arm extended, lift up and
          top arm and top leg also extends and hold the position stable for 30
          seconds, then switch sides.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/vlg7fkaaqv86acco1cdkb/Lateral-Level-8.MOV?rlkey=iagro6po0jmax8qmsv7ii3bdi&st=0ug4egee&dl=0">
            https://www.dropbox.com/scl/fi/vlg7fkaaqv86acco1cdkb/Lateral-Level-8.MOV?rlkey=iagro6po0jmax8qmsv7ii3bdi&st=0ug4egee&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_6.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 9") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 9 : </b>Laying on Side with bottom arm extended, lift up and
          top arm and top leg also extends and hold the position stable for 60
          seconds, then switch sides.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/fntw7pyqxd9a4vm7ux3mf/Lateral-Level-9.MOV?rlkey=toos52zr3mr1xrdkd8thugdjc&st=07t8yp2i&dl=0">
            https://www.dropbox.com/scl/fi/fntw7pyqxd9a4vm7ux3mf/Lateral-Level-9.MOV?rlkey=toos52zr3mr1xrdkd8thugdjc&st=07t8yp2i&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_lateral_6.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }

  return null;
}

function CSSupine({ level }) {
  if (level === "Level 1") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 1 : </b>On the floor face up, feet and arms flat on the floor
          and knees should be shoulder width apart & arms at the side. Raise
          back off the floor hold as long as possible. Goal is 30 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/rat1jqriegmc3mmk0jtc6/Supine-Level-1.MOV?rlkey=uuce4eijm4ukb7fzjrlc78770&st=8f1j8v9p&dl=0">
            https://www.dropbox.com/scl/fi/rat1jqriegmc3mmk0jtc6/Supine-Level-1.MOV?rlkey=uuce4eijm4ukb7fzjrlc78770&st=8f1j8v9p&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 2") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 2 : </b> On the floor face up, feet and arms flat on the
          floor and knees should be shoulder width apart & arms at side. Raise
          back off the floor - Goal is 60 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/szv9sr5axm0tqnzb1j1d4/Supine-Level-2.MOV?rlkey=7eof6dcaexz02p35g0spgwtl3&st=emtrozhv&dl=0">
            https://www.dropbox.com/scl/fi/szv9sr5axm0tqnzb1j1d4/Supine-Level-2.MOV?rlkey=7eof6dcaexz02p35g0spgwtl3&st=emtrozhv&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 3") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 3 : </b>Starting position is on the floor, laying face up,
          feet are together, knees are together, and arms are crossed. Raise
          back off the floor hold this position: Goal is 30 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/53nywy44yhw4bpmu6hg43/Supine-Level-3.MOV?rlkey=la899tzhhof7ys3kp9hhdrqcp&st=k7j69h26&dl=0">
            https://www.dropbox.com/scl/fi/53nywy44yhw4bpmu6hg43/Supine-Level-3.MOV?rlkey=la899tzhhof7ys3kp9hhdrqcp&st=k7j69h26&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 4") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 4 : </b>Starting position is on the floor, laying face up,
          feet are together, knees are together, and arms are crossed. Raise
          back off the floor hold this position: Goal is 60 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/uw35eghojax120b44xm3g/Supine-Level-4.MOV?rlkey=qozl5w6uh4c9g7c9s2qxjo69u&st=gz9uaf14&dl=0">
            https://www.dropbox.com/scl/fi/uw35eghojax120b44xm3g/Supine-Level-4.MOV?rlkey=qozl5w6uh4c9g7c9s2qxjo69u&st=gz9uaf14&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 5") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 5 : </b>Arms crossed over chest, lift leg (one at a time) and
          bottom off the ground and hold for 30 seconds. Then switch and do on
          the other side with the other leg.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/6e88yt5sai619munnu2kn/Supine-Level-5.MOV?rlkey=czl5qadtjobsa1t5q0sy9rj48&st=fecke6tr&dl=0">
            https://www.dropbox.com/scl/fi/6e88yt5sai619munnu2kn/Supine-Level-5.MOV?rlkey=czl5qadtjobsa1t5q0sy9rj48&st=fecke6tr&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 6") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 6 : </b>Arms crossed over chest, lift leg (one at a time) and
          bottom off the ground and hold for 60 seconds. Then switch and do on
          the other side with the other leg.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/pf0f2doqg6dt6gvb9dclv/Supine-Level-6.MOV?rlkey=ub1imxf8e4324e362nnu2vi69&st=0jm1lwl8&dl=0">
            https://www.dropbox.com/scl/fi/pf0f2doqg6dt6gvb9dclv/Supine-Level-6.MOV?rlkey=ub1imxf8e4324e362nnu2vi69&st=0jm1lwl8&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 7") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 7 : </b> Laying on the black, arms crossed over chest and
          feet are on a bosa ball or a box. Lift off the ground to form a bridge
          and hold for 60 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/fy2sdob5628e26wv8lsph/Supine-Level-7.MOV?rlkey=kfj2xjvffgrviptpvwcz20tni&st=mmrmy3zg&dl=0">
            https://www.dropbox.com/scl/fi/fy2sdob5628e26wv8lsph/Supine-Level-7.MOV?rlkey=kfj2xjvffgrviptpvwcz20tni&st=mmrmy3zg&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_4.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 8") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 8 : </b>Laying on the black, arms crossed over chest and feet
          are on a bosa ball or a box. Lift off the ground to form a bridge &
          lift leg, hold for 60 seconds & switch to the other leg and hold for
          60 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/a1n0fykr8h2fwjonyf41t/Supine-Level-8.MOV?rlkey=tzdqstpxnyclick05c2i2vda2&st=mcjgkfzx&dl=0">
            https://www.dropbox.com/scl/fi/a1n0fykr8h2fwjonyf41t/Supine-Level-8.MOV?rlkey=tzdqstpxnyclick05c2i2vda2&st=mcjgkfzx&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_5.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 9") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 9 : </b>Laying on back, use a resistance band across waste
          and hold it with each hand, then lift up into bridge position while
          keeping the band tights across hips/waste and hold for 60 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/1kcq4syuwuefj3211pwhk/Supine-Level-9.MOV?rlkey=k616rclrliurdjqbbmpgyy3dr&st=qjhk4w2t&dl=0">
            https://www.dropbox.com/scl/fi/1kcq4syuwuefj3211pwhk/Supine-Level-9.MOV?rlkey=k616rclrliurdjqbbmpgyy3dr&st=qjhk4w2t&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_6.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }

  if (level === "Level 10") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 10 : </b>Laying on back, use a resistance band across waste
          and hold it with each hand, then lift up into bridge position, then
          lift each leg, one at a time while keeping the band tights across
          hips/waste and hold for 60 seconds.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/w0ji2u45n53dzqvdd5981/Supine-Level-10.MOV?rlkey=i1lhtk9c6rwfd92swricser42&st=1x2lj6ee&dl=0">
            https://www.dropbox.com/scl/fi/w0ji2u45n53dzqvdd5981/Supine-Level-10.MOV?rlkey=i1lhtk9c6rwfd92swricser42&st=1x2lj6ee&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_supine_7.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }

  return null;
}

function CSCurlUps({ level }) {
  if (level === "Level 1") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 1 : </b>Laying down and parent can sit on legs and child will
          hold onto parents thumbs and parent can assist in pulling them up to
          help do a full sit up. Goal is 30 at a time as they grow stronger.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/mrpvkqnf87ev0xebi6uut/kid-situps.mov?rlkey=6dxpq72odn8vih8dbtxev5jg7&dl=0">
            https://www.dropbox.com/scl/fi/mrpvkqnf87ev0xebi6uut/kid-situps.mov?rlkey=6dxpq72odn8vih8dbtxev5jg7&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_curlups_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 2") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 2 : </b>Laying down and parent can sit on legs and child will
          hold onto parents’ thumbs, and parent can assist in pulling them up to
          help do a full sit up. Goal is 50 at a time as they grow stronger and
          build more core strength.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_curlups_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 3") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 3 : </b>Arms Crossed Hands pulling sit ups, 30 Laying down
          and parent can sit on legs and child will hold onto parents’ thumbs
          but for this level, they will cross their arms, so child’s right hand
          is holding parents left hand and child’s left hand is holding parents
          right hand, and parent can assist in pulling them up to help do a full
          sit up. Goal is 30 at a time as they grow stronger and build more core
          strength.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_curlups_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 4") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 4 : </b>Laying on back, arms are crossed over chest and
          parent can assist in holding feet for stability. Proceed to do a full
          sit up and back down for 20 times.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_curlups_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_curlups_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 5") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 5 : </b>Laying on back, arms are crossed over chest and
          parent can assist in holding feet for stability. Proceed to do a full
          sit up and back down for 40 times.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_curlups_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_curlups_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }

  return null;
}

function CSPushUps({ level }) {
  if (level === "Level 1") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 1 : </b>Wheelbarrow walk supported - Parent will hold on
          ankles and lift legs off the ground and they will walk for 10 steps
          forward and 10 steps backwards.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/vcc6unxaauxkcjob0091o/wheel-barrow.MOV?rlkey=eg99njtrfqxgw777z5x62uvrl&dl=0">
            https://www.dropbox.com/scl/fi/vcc6unxaauxkcjob0091o/wheel-barrow.MOV?rlkey=eg99njtrfqxgw777z5x62uvrl&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />{" "}
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 2") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 2 : </b>Wheelbarrow walk – Parent will hold on ankles and
          lift legs off the ground and they will walk for 20 steps forward and
          20 steps backwards.
          <br></br>
          <a href="https://www.dropbox.com/scl/fi/vcc6unxaauxkcjob0091o/wheel-barrow.MOV?rlkey=eg99njtrfqxgw777z5x62uvrl&dl=0">
            https://www.dropbox.com/scl/fi/vcc6unxaauxkcjob0091o/wheel-barrow.MOV?rlkey=eg99njtrfqxgw777z5x62uvrl&dl=0
          </a>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />{" "}
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 3") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 3 : </b>Wheelbarrow Plank – Parent will hold feet up off the
          ground while they are supporting weight on their arms. The goal is to
          hold the position for 60 seconds stable without falling.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 4") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 4 : </b>Plank in Push Up Position – Hands directly below
          shoulders and back is straight and hold as long as possible until he
          become unstable daily and tracking the time. Goal will be to hold in
          his position for 60 seconds.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_4.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 5") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 5 : </b>Using box about 24-30” tall, do 20 push ups for 1 to
          2 times a day to continue to build core strength and stability around
          the spine.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_5.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 6") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 6 : </b>Hands directly below shoulder, knees bent and feet
          off the ground. Lower doing and do 20 push ups on the knees, going as
          far down as possible.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_6.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 7") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 7 : </b>Hands directly below shoulders and back is straight,
          lower down to do a full push up. Goal is 15 times for this level.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_4.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 8") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 8 : </b>Hands directly below shoulders and back is straight,
          lower down to do a full push up. Goal is 25 times for this level.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_4.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }
  if (level === "Level 9") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 9 : </b>Hands directly below shoulders and back is straight,
          lower down to do a full push up.{" "}
          <b>Goal is 40 for Male and 30 for Female. </b>
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/core_pushups_4.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );
  }

  return null;
}

function CSPullUps({ level }) {
  if (level === "Level 1") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 1 : </b>Supported Bar Hang: Recommend the family purchase a
          doorway chin up bar. Level 1 will be to hang from the bar WITH
          parents’ support for 5 seconds.
        </Typography>
      </>
    );
  }
  if (level === "Level 2") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 2 : </b>Supported Bar Hang: Recommend the family purchase a
          doorway chin up bar. Level 2 will be to hang from the bar WITH
          parents’ support for 10 seconds.
        </Typography>
      </>
    );
  }
  if (level === "Level 3") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 3 : </b>Supported Bar Hang: Recommend the family purchase a
          doorway chin up bar. Level 3 will be to hang from the bar WITH parents
          support for 30 seconds.
        </Typography>
      </>
    );
  }
  if (level === "Level 4") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 4 : </b>Bar Hang – Recommend the family purchase a doorway
          chin up bar for the home program. Level 4 will be to be to hang from
          the bar, unsupported (on their own). At first the goal is 15 seconds
          to be able to hang with no help.
        </Typography>
      </>
    );
  }
  if (level === "Level 5") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 5 : </b>Bar Hang – Recommend the family purchase a doorway
          chin up bar for the home program. Level 5 will be to hang from the
          bar, unsupported. At first the goal is 30 seconds for this level.
        </Typography>
      </>
    );
  }
  if (level === "Level 6") {
    return (
      <>
        <Typography variant="body1">
          <b>Level 6 : </b>Bar Hang – Recommend the family purchase a doorway
          chin up bar for the home program. Level 6 is to hang from the bar,
          unsupported for 60 seconds.
        </Typography>
      </>
    );
  }

  return null;
}

function LSEyelights({ eyelight, firstName, genderPronoun, genderAdjective }) {
  if (eyelight === "Blue")
    return (
      <>
        <Typography variant="body1">
          <b>Eyelights (Blue)</b> - This is an item that {firstName} is
          recommended to use daily for {genderAdjective} home program. They will
          be set up so that {genderPronoun} will only see the blinking lights in
          the left half of both of the eyes. This means, when {genderPronoun} is
          looking OUT, {genderPronoun} will see the left half of both eyes
          blinking (NOT when you are looking at them). Recommending that{" "}
          {genderPronoun} use these twice daily, starting with 10-20 minutes and
          increasing the time gradually up to 60 minutes at a time, twice daily
          and combine with using the TENS and Brain Driver at the same time for
          more right brain sensory stimulation.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/eyelights_blue.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );

  if (eyelight === "Red")
    return (
      <>
        <Typography variant="body1">
          <b>Eyelights (Red)</b> - This is an item that {firstName} is
          recommended to use daily for {genderAdjective} home program. They will
          need to be set up so that {genderPronoun} will only see the blinking
          lights in the right half of both of the eyes. This means, when{" "}
          {genderPronoun} is looking OUT, {genderPronoun} will see the right
          half of both eyes blinking (NOT when you are looking at them).
          Recommending that {genderPronoun} use these twice daily, starting with
          10-20 minutes and increasing the time gradually up to 60 minutes at a
          time as {genderPronoun} can tolerate twice daily and combine with TENS
          and Brain Driver at the same time for more left brain sensory
          stimulation.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/eyelights_red.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );

  return null;
}

function SoundTherapy({
  value,
  program,
  firstName,
  genderPronoun,
  genderAdjective,
}) {
  if (value === "Brain Balance Music")
    return (
      <>
        <Typography variant="body1" className="mt-4">
          <b>Brain balance music or {program} brain music</b> - The parents
          should purchase the Brain Balance music for the {program} brain online
          at <a href="www.brainbalancemusic.com">www.brainbalancemusic.com</a>.{" "}
          {firstName} should listen to the music in just the{" "}
          {program === "left" ? "right" : "left"}
          ear with just 1 ear bud in or using just one headphone for 30 minutes
          for 1 to 2 times daily.
        </Typography>
        <Typography variant="h5" className="mt-2">
          Additional {program} brain music recommendations:
        </Typography>
        <a href="https://open.spotify.com/playlist/0nWjBFv6DiCqN0mPn5VAgr?si=EaVev-aIQLux78uYJ1A-Ug&nd=1&dlsi=b358016764e14dee">
          https://open.spotify.com/playlist/0nWjBFv6DiCqN0mPn5VAgr?si=EaVev-aIQLux78uYJ1A-Ug&nd=1&dlsi=b358016764e14dee
        </a>
      </>
    );

  if (value === "Metro Timer")
    return (
      <>
        <Typography variant="body1" className="mt-2">
          <b>Metro Timer</b> - {genderPronoun} can also use the Metro Timer app
          downloaded onto a phone also as part of {genderAdjective} daily home
          program. {genderPronoun} will be using it at 54 BPM and hold the phone
          about 6 – 8 “ away from {genderAdjective}{" "}
          {program === "left" ? "right" : "left"} ear and allow the sound to
          play for 30 seconds, 1 to 2 times per day. {genderPronoun} can also
          use the flashing light from this app, having that blink in the outside
          corner of {genderAdjective} {program === "left" ? "right" : "left"}{" "}
          eye for 30 seconds, 1 to 2 times per day.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/metrotimer_1.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
          <img
            src="../../user-forms/doctor-assessment/exercise-images/metrotimer_2.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
          <img
            src="../../user-forms/doctor-assessment/exercise-images/metrotimer_3.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );

  if (value === "Tonal Therapy") return <></>;

  return null;
}

function Smell({ value, program }) {
  if (value === "Aromatherapy")
    return (
      <Typography variant="body1" className="mt-2">
        <b>Aromatherapy</b> - Recommend that they use {program}
        -brain scents on the {program} collar of shirt 2 to 3 times per day.{" "}
        {program} Brain scents are Peppermint, Lemon, Eucalyptus, Black Pepper,
        Coffee, Onion and Lime.
      </Typography>
    );

  if (value === "Smell Sniff / Detection Exercise")
    return (
      <>
        <Typography variant="body1" className="mt-2">
          <b>Smell Detection Exercise</b> - With eyes closed, he will smell 2{" "}
          {program} brain scents using both sides of his nose. He will need to
          try to identify what the smell is (Example: Point or say which one is
          Lemon). He will continue to use those same 2 smells every day and try
          to identify them without seeing them until he gets them correct, then
          you can switch to new {program} brain scents and continue, and this
          will increase his smell recognition and processing in the brain.
        </Typography>
        <div className="d-flex justify-content-around">
          <img
            src="../../user-forms/doctor-assessment/exercise-images/smell_detection.png"
            alt="exercise"
            width={400}
            className="m-4"
          />
        </div>
      </>
    );

  if (value === "Smell Recognition / Processing") return <></>;

  return null;
}

function RhythmicMovement({ value, genderPronoun, genderAdjective }) {
  if (value === "Clap Hands Together")
    return (
      <>
        <Typography variant="body1">
          <b>Clap Hands together</b> - {genderPronoun} will do circular
          movements, clap to match the sound of the metrotimer or metronome at
          54 beats per minute starting at 20 reps, each week add 10 till get to
          100 reps per day. Tap Feet – Using the same 54 BPM, {genderPronoun}{" "}
          can also tap {genderAdjective} feet to that beat and match the rhythm
          of the Metrotimer with {genderAdjective} taps, starting with 20 reps
          and working up to 100 per day.
        </Typography>
      </>
    );

  if (value === "Tap feet to Metronome Sound")
    return (
      <>
        <Typography variant="body1">
          <b>Tap Feet to Metronome Sound</b> – Using the same 54 BPM,{" "}
          {genderPronoun} will tap {genderAdjective} feet to match the beat of
          the metrotimer sound. Again, starting with 20 reps and increasing to
          100 at a time.
        </Typography>
      </>
    );

  if (value === "Tap hands and feet together")
    return (
      <Typography variant="body1">
        <b>Tap Hands and Feet Together</b> – Still using 54 BPM, {genderPronoun}{" "}
        will alternate tapping {genderAdjective} hands and then one foot, then
        hands and other foot at the same time. Starting with 20 reps and
        increasing up to 100 Reps per day as part of the home program.
      </Typography>
    );

  if (value === "Interactive Metronome Home Program")
    return (
      <Typography variant="body1">
        <b>Interactive Metronome Home Program</b> – We will also give the family
        information to purchase the Home Interactive Metronome equipment for the
        daily home program. This is an excellent piece of equipment to help
        increase rhythm and timing in the brain and we can set up and monitor
        the progress remotely for this equipment as well.
      </Typography>
    );

  if (value === "Interactive Metronome in Office")
    return (
      <Typography variant="body1">
        <b>Interactive Metronome in Office</b> – We will also give the family
        information to purchase the Home Interactive Metronome equipment for the
        daily home program. This is an excellent piece of equipment to help
        increase rhythm and timing in the brain and we can set up and monitor
        the progress remotely for this equipment as well.
      </Typography>
    );
  if (value === "Interactive Metronome Home and Office")
    return (
      <Typography variant="body1">
        <b>Interactive Metronome Home and Office</b> – We will also give the
        family information to purchase the Home Interactive Metronome equipment
        for the daily home program. This is an excellent piece of equipment to
        help increase rhythm and timing in the brain and we can set up and
        monitor the progress remotely for this equipment as well.
      </Typography>
    );
  return null;
}

function Vitamin({ vitamin, firstName }) {
  if (vitamin.includes("Omega"))
    return (
      <Typography variant="body1" className="mt-2">
        I have recommended that {firstName} should be taking {vitamin}. Omega is
        very important for brain function, brain development as well as reducing
        inflammation and controlling the immune system. This is available in
        liquid and capsules if needed.
        <br></br>
        <a href="https://www.dropbox.com/scl/fi/2ty1lxjb6emc9tg6p6qqn/omegas-vits.mov?rlkey=gk7wc12l3aep22k8t5qqsoj0s&dl=0">
          https://www.dropbox.com/scl/fi/2ty1lxjb6emc9tg6p6qqn/omegas-vits.mov?rlkey=gk7wc12l3aep22k8t5qqsoj0s&dl=0
        </a>
      </Typography>
    );
  if (vitamin.includes("Vitamin D"))
    return (
      <Typography variant="body1" className="mt-2">
        I have recommended that {firstName} should be taking {vitamin} daily of
        each. Vitamin D also helps to balance the immune system in the body.
        This is available in liquid and gummies if needed.
        <br></br>
        <a href="https://www.dropbox.com/scl/fi/kmry9tlraa8smeul33zom/vitamin-Ds.mov?rlkey=vaalvez7ap8yj3uoad8cdcu6y&dl=0">
          https://www.dropbox.com/scl/fi/kmry9tlraa8smeul33zom/vitamin-Ds.mov?rlkey=vaalvez7ap8yj3uoad8cdcu6y&dl=0
        </a>
      </Typography>
    );
  if (vitamin.includes("Kid Genius"))
    return (
      <Typography variant="body1" className="mt-2">
        I am going to also recommend that {firstName} starts on the KidGenius
        Attention vitamins (right brain) and the Digestion Probiotic
        supplements, {vitamin} daily preferably with food. The right brain
        vitamins provide neurotransmitters for right brain development and the
        digestion vitamins help to provide important digestive enzymes to the
        body.
        <br></br>
        <a href="https://www.dropbox.com/scl/fi/2dqvr1iru7mqc3ikhitn2/Kid-Genius-Digestion-Vitamins.mov?rlkey=h736cntv8hqrf2vldr028ztm5&dl=0">
          https://www.dropbox.com/scl/fi/2dqvr1iru7mqc3ikhitn2/Kid-Genius-Digestion-Vitamins.mov?rlkey=h736cntv8hqrf2vldr028ztm5&dl=0
        </a>
        <br></br>
        <a href="https://www.dropbox.com/scl/fi/9avmvngpc7ipeh5xoyv0s/Kid-Genius-Attention-Vitamins.mov?rlkey=2k4ayirdxeq2yi7jr6rzng4ff&dl=0">
          https://www.dropbox.com/scl/fi/9avmvngpc7ipeh5xoyv0s/Kid-Genius-Attention-Vitamins.mov?rlkey=2k4ayirdxeq2yi7jr6rzng4ff&dl=0
        </a>
      </Typography>
    );
  if (vitamin.includes("Zeolite/Glutathione"))
    return (
      <Typography variant="body1" className="mt-2">
        I have made additional supplement recommendations to the family for{" "}
        {firstName}. The Glutathione Spray & Zeolite Spray would be something{" "}
        {firstName} should be using daily. Glutathione is an all-natural
        chemical that is made in the body and Zeolite helps to excrete toxins.
        These are used twice daily, for {vitamin} and are completely odorless
        and tasteless. We sell this in the office or on Amazon.
        <br></br>
        <a href="ttps://www.dropbox.com/scl/fi/bwxuthjgfszxanvoeaxqi/sprays.mov?rlkey=1q0hk56m1hb85rbakuusi321c&dl=0">
          ttps://www.dropbox.com/scl/fi/bwxuthjgfszxanvoeaxqi/sprays.mov?rlkey=1q0hk56m1hb85rbakuusi321c&dl=0
        </a>
      </Typography>
    );
  if (vitamin.includes("TH1/TH2"))
    return (
      <Typography variant="body1" className="mt-2">
        The Th1 and Th2 supplements would also be beneficial for {firstName},{" "}
        {vitamin}. These are used to build the immune system, fight infections
        and create antibodies. They are available in our office or on
        www.pureforyou.com and will be taken daily with food.
        <br></br>
        <a href="https://www.dropbox.com/scl/fi/sgk9kjzrkfkzlkulcv75l/th-vits.mov?rlkey=h8ps5sqcbz0bg4s89l7eyzyf1&dl=0">
          https://www.dropbox.com/scl/fi/sgk9kjzrkfkzlkulcv75l/th-vits.mov?rlkey=h8ps5sqcbz0bg4s89l7eyzyf1&dl=0
        </a>
      </Typography>
    );

  return null;
}

export default StimFormReport;
