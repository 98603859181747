import { useState, useEffect, useCallback, useRef } from "react";
import { Paper, Stack, Tooltip, CircularProgress, Button } from "@mui/material";
import { useParams } from "react-router";
import { useOutletContext, useNavigate } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BarChartIcon from "@mui/icons-material/BarChart";

import EnhancedTable from "components/utils/Table.js";
import EnumUtils from "utils/EnumUtils.js";
import FormUtils from "utils/FormUtils.js";
import ButtonDropdown from "components/utils/ButtonDropdown.js";
import PopUpModal from "components/utils/PopUpModal.js";
import DateUtils from "utils/DateUtils.js";
import TableRowActionsCell from "components/utils/TableRowActionsCell.js";
import {
  DOCTOR_ASSESSMENT_FORM_TYPES,
  DOCTOR_STIM_FORM_TYPES,
} from "utils/constants/DoctorAssessmentFormTypes.js";

import AuditFieldsModal from "components/modals/AuditFieldsModal.js";
import ConfirmActionModal from "components/modals/ConfirmActionModal";
import StimReportPDF from "./StimFormReport";

import useAPI from "hooks/useAPI.js";
import DoctorFormService from "services/DoctorFormService.js";

function Assessments() {
  const [hospitalId] = useOutletContext();
  const { patientId } = useParams();
  const [formsList, setFormsList] = useState([]);
  const [popUpModalContent, setPopUpModalContent] = useState({
    createdAt: "",
    lastUpdatedAt: "",
    createdBy: "",
    lastUpdatedBy: "",
  });
  const [stimReportData, setStimReportData] = useState({});

  const deleteAssessmentModalRef = useRef(null);
  const [deletingAssessmentId, setDeletingAssessmentId] = useState("");

  let navigate = useNavigate();
  const popUpModalRef = useRef(null);
  const stimReportPopUpModalRef = useRef(null);

  const getFormsAPI = useAPI(
    DoctorFormService.getAssessmentForms,
    getFormsHandler
  );
  const addAssessmentFormAPI = useAPI(
    DoctorFormService.addAssessmentForm,
    addAssessmentFormHandler
  );

  const deleteAssessmentFormAPI = useAPI(
    DoctorFormService.deleteAssessmentForm,
    deleteAssessmentFormHandler,
    true
  );

  const revokeAssessmentFormAPISubmission = useAPI(
    DoctorFormService.revokeAssessmentFormSubmission,
    revokeAssessmentFormSubmissionHandler,
    true
  );

  const getStimFormReportDataAPI = useAPI(
    DoctorFormService.getStimFormReportData,
    getStimFormReportDataHandler
  );

  const getForms = useCallback(() => {
    getFormsAPI.execute(hospitalId, patientId);
  }, [formsList]);

  useEffect(() => {
    getForms();
  }, []);

  function addAssessmentForm(formType) {
    addAssessmentFormAPI.execute(hospitalId, patientId, {
      doctorAssessmentFormType: formType,
    });
  }

  function deleteAssessmentForm(formId) {
    deleteAssessmentFormAPI.execute(hospitalId, patientId, formId);
  }

  function revokeAssessmentFormSubmission(formId) {
    revokeAssessmentFormAPISubmission.execute(hospitalId, patientId, formId);
  }

  function getStimFormReportData(formId) {
    getStimFormReportDataAPI.execute(hospitalId, patientId, formId);
  }

  /* API Success Handlers */

  function getFormsHandler(response) {
    let data = response.data;
    let list = [];
    data.map((form, index) => list.push(transformJsonData(form, index + 1)));
    setFormsList(list);
  }

  function addAssessmentFormHandler(response) {
    getForms();
  }

  function deleteAssessmentFormHandler(response) {
    getForms();
  }

  function revokeAssessmentFormSubmissionHandler(response) {
    getForms();
  }

  function getStimFormReportDataHandler(response) {
    setStimReportData(response.data);
    stimReportPopUpModalRef.current.openModal();
  }

  return (
    <Paper elevation={0}>
      <div className="d-flex justify-content-between p-3">
        {doesFormsContainAssessments(formsList) ? (
          <Button
            type="button"
            className=""
            startIcon={<BarChartIcon />}
            variant="outlined"
            onClick={() =>
              navigate(
                `/hospital/assessment-analysis?hospitalID=${hospitalId}&patientId=${patientId}`
              )
            }
          >
            Analysis
          </Button>
        ) : (
          <div></div>
        )}
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          spacing={3}
        >
          <ButtonDropdown
            menuItems={DOCTOR_ASSESSMENT_FORM_TYPES}
            onClickHandler={addAssessmentForm}
          />
          <ButtonDropdown
            menuItems={DOCTOR_STIM_FORM_TYPES}
            onClickHandler={addAssessmentForm}
          />
        </Stack>
      </div>
      {getFormsAPI.status === "pending" ? (
        <div className="text-center">
          <CircularProgress
            className="text-center"
            sx={{
              my: 20,
            }}
          />
        </div>
      ) : (
        <EnhancedTable
          headings={tableHeadings}
          rowsData={formsList}
          rowsPerPage={10}
        />
      )}
      <PopUpModal ref={popUpModalRef} modalWidth={320}>
        <AuditFieldsModal data={popUpModalContent} />
      </PopUpModal>
      <PopUpModal ref={deleteAssessmentModalRef} modalWidth={400}>
        <ConfirmActionModal
          content="Do you want to confirm deleting the assessment?"
          confirmActionHandler={() => {
            deleteAssessmentForm(deletingAssessmentId);
            deleteAssessmentModalRef.current.closeModal();
          }}
          onCloseHandler={() => deleteAssessmentModalRef.current.closeModal()}
        />
      </PopUpModal>
      <PopUpModal ref={stimReportPopUpModalRef} modalWidth={"90%"}>
        <StimReportPDF reportData={stimReportData} />
      </PopUpModal>
    </Paper>
  );

  function transformJsonData(form, index) {
    return {
      id: form.id,
      index: index,
      form_type: EnumUtils.parse(form.assessmentFormTypeEnum),
      status: FormUtils.getStatusChip(form.formStatusTypeEnum),
      actions: (
        <ActionsCell
          hospitalId={hospitalId}
          patientId={patientId}
          form={form}
        />
      ),
    };
  }

  function ActionsCell({ hospitalId, patientId, form }) {
    const actions = {
      startItems: [
        {
          component: (
            <Tooltip title="View Form">
              <VisibilityIcon fontSize="inherit" color="secondary" />
            </Tooltip>
          ),
          onClickHandler: () =>
            navigateToForm(
              form.assessmentFormTypeEnum,
              hospitalId,
              patientId,
              form.isMigrated,
              form.id,
              form.formStatusTypeEnum
            ),
        },
        {
          component: (
            <Tooltip title="Delete Form">
              <DeleteOutlineIcon fontSize="inherit" color="error" />
            </Tooltip>
          ),
          onClickHandler: () => {
            setDeletingAssessmentId(form.id);
            deleteAssessmentModalRef.current.openModal();
          },
        },
      ],
      menuItems: [
        {
          label: "View Information",
          onClickHandler: () => {
            setPopUpModalContent({
              createdAt: DateUtils.formatDateTime(form.createdAt),
              lastUpdatedAt: DateUtils.formatDateTime(form.lastUpdatedAt),
              createdBy: form.createdByUserFullName,
              lastUpdatedBy: form.lastUpdatedByUserFullName,
            });
            popUpModalRef.current.openModal();
          },
        },
      ],
    };

    if (form.assessmentFormTypeEnum.includes("STIM")) {
      if (form.formStatusTypeEnum !== "NOT_STARTED")
        actions.menuItems.push({
          label: "Generate Report",
          onClickHandler: () => getStimFormReportData(form.id),
        });
    }

    if (form.formStatusTypeEnum === "SUBMITTED") {
      actions.menuItems.push({
        label: "Revoke Submission",
        onClickHandler: () => revokeAssessmentFormSubmission(form.id),
      });
    }

    return <TableRowActionsCell actions={actions} />;
  }
}

function doesFormsContainAssessments(formsList) {
  let flag = false;
  formsList.forEach((item) => {
    if (item.form_type.includes("Assessment")) {
      flag = true;
    }
  });
  return flag;
}

function navigateToForm(
  patientFormTypeEnum,
  hospitalId,
  patientId,
  isMigrated,
  assessmentFormID,
  formStatus
) {
  let viewMode = "write";
  if (formStatus === "SUBMITTED") {
    viewMode = "read";
  }
  if (patientFormTypeEnum === "EXTENDED_ASSESSMENT") {
    if (isMigrated)
      // Old Form
      window.open(
        `user-forms/doctor-assessment/migrated/extended-assessment.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=read`,
        "_blank"
      );
    else
      window.open(
        `user-forms/doctor-assessment/extended-assessment.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=${viewMode}`,
        "_blank"
      );
  } else if (patientFormTypeEnum === "GENERAL_ASSESSMENT") {
    if (isMigrated)
      // Old Form
      window.open(
        `user-forms/doctor-assessment/migrated/general-assessment.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=read`,
        "_blank"
      );
    else
      window.open(
        `user-forms/doctor-assessment/general-assessment.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=${viewMode}`,
        "_blank"
      );
  } else if (patientFormTypeEnum === "BASIC_ASSESSMENT") {
    if (isMigrated)
      // Old Form
      window.open(
        `user-forms/doctor-assessment/migrated/basic-assessment.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=read`,
        "_blank"
      );
    else
      window.open(
        `user-forms/doctor-assessment/basic-assessment.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=${viewMode}`,
        "_blank"
      );
  } else if (patientFormTypeEnum === "EXTENDED_STIM") {
    if (isMigrated)
      // Old Form
      window.open(
        `user-forms/doctor-assessment/migrated/extended-stim.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=read`,
        "_blank"
      );
    else
      window.open(
        `user-forms/doctor-assessment/extended-stim.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=${viewMode}`,
        "_blank"
      );
  } else if (patientFormTypeEnum === "GENERAL_STIM") {
    window.open(
      `user-forms/doctor-assessment/general-stim.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "BASIC_STIM") {
    window.open(
      `user-forms/doctor-assessment/basic-stim.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else if (patientFormTypeEnum === "VIRTUAL_STIM") {
    window.open(
      `user-forms/doctor-assessment/virtual-stim.html?hospitalID=${hospitalId}&patientID=${patientId}&assessmentFormID=${assessmentFormID}&viewMode=${viewMode}`,
      "_blank"
    );
  } else {
    alert("Invalid Patient Assessment Form Type");
  }
}

const tableHeadings = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "sno",
    label: "S.No.",
  },
  {
    id: "form_type",
    label: "Form Type",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "view",
    label: "",
  },
];

export default Assessments;
